import React from 'react';
import {Card,Button,TextField, CircularProgress} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import * as ETVConstants from '../ETVConstants';
import { Save } from '@mui/icons-material';

class EditFloorNames extends React.Component {

	constructor(props) {
      super(props);
    }

    state = {
		isLoaded:false,
		isLoading:false,
		ioProps:{},
		ioCodes:[],
		code_type:'gray',
		bits:'6',
		info_message:"",
		info_title:"",
    }

    componentDidMount() {
	    if(!this.state.isLoaded) {
			this.loadItems();
		}
    }

    loadItems() {
		this.setState({isLoaded:false,isLoading:true});
		var url = ETVConstants.getServerBase()+"/elevator_config?device_id="+this.props.device_id;
		//console.log("url="+url);
		fetch(url)
			.then(res => res.json())
			.then(
			(result) => {
				//console.log("loaded Items="+JSON.stringify(result));
				var codeType = 'gray';
				if(result['code_type']) {
					codeType = result['code_type'];
				}
				this.setState({
					//ioProps:result,
					code_type:codeType,
					isLoaded: true,
					isLoading:false,
				});
				// this will create the codes
				this.updateCodeType(codeType,false,result);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				this.setState({
				ioProps: undefined,
				isLoaded: false,
				isLoading: false,
				error
				});
			}
		)
    }

    showWindow(divID, show) {
      	show?this.setState({window:divID}):this.setState({window:''});
    }

    getItem( deviceID) {
      	for(var i=0; i<this.state.items.length; i++) {
        	if(this.state.items[i].id===deviceID) {
          		return this.state.items[i];
        	}
      	}
      	return undefined;
    }

	setFloorValue( key, value) {
		//console.log("setting key="+key+" value="+value);
		var props = this.state.ioProps;
		props[key]=value;
		this.setState({ioProps:props});
	}

	createIOItem( code,index) {
		//console.log("create IO Item, code="+code);
		var ioProps = this.state.ioProps;

		if(ioProps) {
			var curVal = ioProps[code];
		}
		var code1 ="";
		if(index<this.state.ioCodes.length-1) {
			code1 = this.state.ioCodes[index+1]
			//console.log("code["+index+"]="+code+" code["+(index+1)+"]="+code1);
		}
		
		//console.log("----> code: "+code+" val: "+curVal+" ioProps="+this.state.ioProps);
		return( 
			<tr key={"tr_"+code}>
				<td><TextField style={{marginTop:7}} size='small' key={code} label="i/o code" variant="outlined" disabled={true} value={code} /></td>
				{ioProps?
					<span>
						<td><TextField style={{marginTop:7}} size='small' key={code} variant="outlined" label='floor name' value={ioProps[code]} onChange={(e)=>{this.setFloorValue(code,e.target.value)}} /></td>
						{index===(this.state.ioCodes.length-1)?<td/>:<td><TextField style={{marginTop:7}} size='small' key={code} variant="outlined" type='number' label='floor distance' value={this.state.ioProps["dist_"+code+"_"+code1]} onChange={(e)=>{this.setFloorValue("dist_"+code+"_"+code1,e.target.value)}} /></td>}
					</span>
					:
					<td/>
				}
			</tr>
		)
	}	

	// see: https://www.geeksforgeeks.org/generate-n-bit-gray-codes/
	generateGrayCode( n) {
	var arr = [];
		arr.push("0");
		arr.push("1");
		var i, j;
		for (i = 2; i < (1<<n); i = i<<1) {
			for (j = i-1 ; j >= 0 ; j--)
				arr.push(arr[j]);

			for (j = 0 ; j < i ; j++)
				arr[j] = "0" + arr[j];

			for (j = i ; j < 2*i ; j++)
				arr[j] = "1" + arr[j];
		}
		return arr;
	}

	generateCanOpenLiftCodes( n) {
		var arr = [];
		for(var i=0; i<n; i++) {
			arr.push(i)
		}
		return arr;
	}

	updateCodeType( code, reset, ioProps) {
		if (code==='gray') {
			var arr = this.generateGrayCode(this.state.bits);

		}else if(code==='binary'){
			var arr = this.generateBinaryCode(this.state.bits);

		}else if(code==='bit_per_floor'){
			var arr = this.generateBitPerFloorCode(10);

		}else if(code==='can_open_lift') {
			var arr = this.generateCanOpenLiftCodes(20);
		}

		var props = ioProps;
		if(reset) {
			props = {};
		}
		if (props) {
			props["code_type"]=code;
			arr.map( function(code,index) {
				var curVal = props[code];
				//console.log("code="+code+" this.state.ioProps[code]="+curVal)
				props[code]=curVal?curVal:index;
			});
		}
		this.setState({ioCodes:arr, code_type:code, ioProps:props});
	}

	generateBinaryCode( bits) {
			var arr = [];
			var maxNo = Math.pow(2,bits);
			//console.log("generate Binary code, maxNo="+maxNo);
			for(var i=0; i<maxNo; i++) {
				arr[i] = this.fillZeros(bits,i.toString(2));
			}
			return arr;
	}

	reverseString(s){
		return s.split("").reverse().join("");
	}

	generateBitPerFloorCode( totalFloors) {
		var arr = [];
		for(var whichFloor=0; whichFloor<totalFloors; whichFloor++) {
			var s = this.createZeros(totalFloors);
			var before = s.substring(0,whichFloor);
			var after = s.substring(whichFloor+1,s.length);
			s = before + "1" + after;
			arr[whichFloor] = this.reverseString(s);
		}
		return arr;
	}

	createZeros(nof) {
		var s ="";
		for(var i=0; i<nof; i++) {
			s = "0"+s;
		}
		return s;
	}

	fillZeros( bits, code) {
		var nof = Math.max(0,bits - code.length);
		for(var i=0; i<nof; i++) {
			code = "0"+code;
		}
		return code;
	}


	showInfoMessage(title,msg) {
		this.showWindow('message_div',true);
		this.setState({info_title:title, info_message:msg});
	}

	closeInfoMessage() {
		this.setState({info_message:undefined});
		this.showWindow('message_div',false);
	}

	saveMapping() {
		this.showInfoMessage("Floor Mapping","saving...");
		//console.log("---------------------save mapping");
		var configJSONStr = JSON.stringify(this.state.ioProps);
		//console.log(configJSONStr);
		
		var url = ETVConstants.getServerBase()+"/elevator_config?action=save_floor_mapping&device_id="+this.props.device_id+"&config_json="+encodeURIComponent(configJSONStr);
		//console.log("url="+url);
		fetch(url)
		.then(res => res.json())
		.then(
			(result) => {
				//console.log("result="+JSON.stringify(result, null, 2));
				this.showInfoMessage("Floor Mapping","success");
				//console.log("xxxxxxxxxxxxxx="+result["fileurl"]);
			},
			// Note: it's important to handle errors here
			// instead of a catch() block so that we don't swallow
			// exceptions from actual bugs in components.
			(error) => {
				this.setState({
					ioProps: undefined,
					isLoaded: false,
					error
				});
			}
		)
	}

    render() {
		if(!this.state.isLoaded) {
			return <div style={{display:'block',margin:'auto',width:'100%',height:'100%'}} ><CircularProgress /></div>
		}
	    return(
			<div>
				<Typography variant="h6">DeviceID: {this.props.device_id}</Typography>
				<Typography variant="body2">{ETVConstants.trans("edit_floor_names_help")}</Typography>
				<FormControl style={{marginTop:20}}>
					<InputLabel>Code</InputLabel>
					<Select
						label="code"
						id="code_format"
						style={{ width: 250}}
						size="small"
						value={this.state.code_type}
						onChange={(e)=>{this.updateCodeType(e.target.value,true)}}>
						<MenuItem value='gray'>Gray Code</MenuItem>
						<MenuItem value='binary'>Binary Code</MenuItem>
						<MenuItem value='bit_per_floor'>Bit per Floor</MenuItem>
						<MenuItem value='can_open_lift'>Can Open Lift</MenuItem>
					</Select>
				</FormControl>

				<table style={{marginTop:15}}>
					<tbody>
						{this.state.ioCodes.map( (code,index) => { return this.createIOItem(code,index)})}
					</tbody>
				</table>
				<Button style={{marginTop:20}} startIcon={<Save/>} variant="contained" color="primary" onClick={() => this.saveMapping()}>{ETVConstants.trans("save")}</Button>

				<Dialog
					open={this.state.window==='message_div'}
					maxWidth='xs'
					fullWidth={true}
					onClose={() => this.setState({window:'',aborted:true, show_progress:false})  }
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{this.state.info_title}</DialogTitle>
					<DialogContent>
						<Typography variant='body1'>{this.state.info_message}</Typography>
						{this.state.show_progress?<CircularProgress/>:<span/>}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.setState({window:'',aborted:true, showProgress:false}) } color="primary">{ETVConstants.trans("close")}</Button>
					</DialogActions>
				</Dialog>

			</div>
		);
	}
}

export default EditFloorNames
